<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-sm  btn-primary"
      @click.prevent="setSelected"
      data-toggle="modal"
      data-target="#update-type-chirurgie"
    >
      <i class="icofont icofont-pencil" />
    </a>
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-third"
      @click.prevent="deleting"
    >
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>
  
  <script>
  import { mapMutations } from 'vuex';
  import { DELETE_TYPE_CHIRURGIE } from '../../../../graphql/sanitaire'; 
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
        ...mapMutations({
            setSelectedObject: 'SET_SELECTED_OBJECT',
        }),
        setSelected(){
            this.setSelectedObject(this.object)
        },
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_TYPE_CHIRURGIE,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Type chirurgie ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting type chirurigie ${this.object.uid}`)
                }
              })
        }
      }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .actions{
      a{
          margin-left: 5px;
      }
  }
  </style>